import { ApiService, RequestOptions } from './ApiService'
import { IApiIndividualResponse } from '@/types/api'
import { ISession } from '@/types/session'

export type IAuthPostPayload = {
  firstName: string
  lastName: string
  email: string
  redirectTo?: string
  companyCode?: string
  invitation?: string
}

export type IAuthPasswordLessVerifyPayload = {
  token: string
}

export type IAuthPasswordLessVerifyResponse = {
  accessToken: string
  expiresIn: string
}

export type IAuthLoginResponse = IAuthPasswordLessVerifyResponse

export type IPasswordLessAuthLoginPayload = {
  email: string
}

export type IAuthLoginPayload = {
  email: string
  password: string
}

export type IAuthGoogleLoginPayload = {
  redirectUrl: string
}

export type IAuthGoogleLoginResponse = {
  location: string
}

class AuthService extends ApiService {
  static POST_PATH_PASSWORD_LESS_REGISTER = '/auth/passwordless/register'
  static POST_PATH_PASSWORD_LESS_VERIFY = '/auth/passwordless/verify'
  static POST_PATH_PASSWORD_LESS_LOGIN = '/auth/passwordless/login'
  static POST_PATH_LOGIN = '/auth/login'
  static POST_PATH_LOGOUT = '/auth/logout'
  static GET_PATH_GOOGLE_AUTH = '/auth/google'

  static async passwordLessRegister(
    payload: IAuthPostPayload
  ): Promise<IApiIndividualResponse<ISession>> {
    const { invitation, ...rest } = payload
    const params = invitation ? `?invitation=${invitation}` : ''
    return ApiService.post(
      `${AuthService.POST_PATH_PASSWORD_LESS_REGISTER}${params}`,
      {
        ...rest,
      }
    )
  }

  static async passwordLessVerify(
    payload: IAuthPasswordLessVerifyPayload
  ): Promise<IApiIndividualResponse<IAuthPasswordLessVerifyResponse>> {
    return ApiService.post(AuthService.POST_PATH_PASSWORD_LESS_VERIFY, {
      ...payload,
    })
  }

  static async passwordLessLogin(
    payload: IPasswordLessAuthLoginPayload
  ): Promise<IApiIndividualResponse<ISession>> {
    return ApiService.post(AuthService.POST_PATH_PASSWORD_LESS_LOGIN, {
      ...payload,
    })
  }

  static async logout(): Promise<void> {
    return ApiService.post(AuthService.POST_PATH_LOGOUT, {})
  }

  static async loginGoogle(
    payload: IAuthGoogleLoginPayload
  ): Promise<IApiIndividualResponse<IAuthGoogleLoginResponse>> {
    return ApiService.post(AuthService.GET_PATH_GOOGLE_AUTH, {
      ...payload,
    })
  }

  static async login(
    payload: IAuthLoginPayload,
    setAccessToken = true,
    options?: RequestOptions
  ): Promise<IApiIndividualResponse<IAuthLoginResponse>> {
    const res = (await ApiService.post(AuthService.POST_PATH_LOGIN, {
      ...payload,
      options,
    })) as IApiIndividualResponse<IAuthLoginResponse>

    const {
      data: { accessToken },
    } = res

    if (setAccessToken) {
      ApiService.setAccessToken(accessToken)
    }

    return res
  }
}

export default AuthService
