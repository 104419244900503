import AuthService from '@/app/services/auth'
import { ErrorResponse } from '@/types/api'
import { useMutation } from 'react-query'
import Cookies from 'js-cookie'
import { USER_INFO_COOKIE_NAME } from '@/app/lib/constants'

export function useAuthLogout() {
  return useMutation<void, ErrorResponse, void>(
    AuthService.POST_PATH_LOGOUT,
    AuthService.logout,
    {
      onSuccess: () => {
        Cookies.remove(USER_INFO_COOKIE_NAME)
      },
    }
  )
}
