'use client'

import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { ROUTES } from '@/app/lib/constants'
import { useAuth } from '@/app/providers/Auth/Auth'
import { PrimaryButton } from '../PrimaryButton'
import { useAuthLogout } from '@/app/hooks/auth/useAuthLogout'
import * as auth from '@/app/lib/auth'
import { Dropdown } from 'flowbite-react'
import LogoutIcon from '@/app/icons/LogoutIcon/LogoutIcon'
import PersonIcon from '@/app/icons/PersonIcon/PersonIcon'
import WalletIcon from '@/app/icons/WalletIcon/WalletIcon'
import CheckListIcon from '@/app/icons/CheckListIcon/CheckListIcon'
import MenuIcon from '@/app/icons/MenuIcon/MenuIcon'
import CloseIcon from '@/app/icons/CloseIcon/CloseIcon'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { SecondaryButton } from '../SecondaryButton'

const MENU_ROUTES = {
  title: 'menu',
  routes: [],
}

const ACCOUNT_ROUTES = {
  title: 'account',
  routes: [
    { title: 'profile', route: ROUTES.myProfile, icon: PersonIcon },
    { title: 'my_orders', route: ROUTES.myOrders, icon: CheckListIcon },
    { title: 'financing', route: ROUTES.myFinancing, icon: WalletIcon },
  ],
}

export type HeaderProps = {
  transparent?: boolean
  showLogout?: boolean
  hideButtons?: boolean
  buttonsSlot?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const Header = ({
  transparent,
  showLogout,
  hideButtons,
  buttonsSlot,
  ...props
}: HeaderProps) => {
  const router = useRouter()
  const { isAuthenticated = false, firstName, lastName } = useAuth()
  const { mutateAsync: logout } = useAuthLogout()
  const [menuOpen, setMenuOpen] = useState(false)
  const t = useTranslations('header')

  const onLogout = async () => {
    try {
      await logout()
    } catch {
      // Do nothing
    }
    auth.logout(router.locale)
  }

  return (
    <header
      {...props}
      className={`${
        transparent ? 'bg-transparent' : 'bg-white border-b'
      } h-[72px] shrink-0 sticky left-0 top-0 w-full z-40 md:static ${props.className ?? ''}`}
    >
      <div className="container flex flex-row items-center justify-between h-full">
        <div className="flex items-center gap-9">
          <Link href={ROUTES.homepage}>
            <Image
              src="/images/mydra-lg.png"
              width={1000}
              height={250}
              alt={t('logo_alt')}
              priority
              className="w-auto h-8"
            />
          </Link>
          {MENU_ROUTES.routes.map(({ title, route }) => (
            <Link href={route} className="hidden md:flex" key={route}>
              <span className="text-base text-black">{t(title)}</span>
            </Link>
          ))}
        </div>

        <div className="space-x-2 justify-items-end">
          {!isAuthenticated && !hideButtons && !buttonsSlot && (
            <Link
              className="px-4 font-semibold min-w-fit hover:opacity-60"
              href={{
                pathname: ROUTES.login,
                query: { redirectTo: window.location.pathname },
              }}
            >
              {t('login')}
            </Link>
          )}
          {!isAuthenticated && (!hideButtons || buttonsSlot) && (
            <>
              {buttonsSlot ? (
                buttonsSlot
              ) : (
                <PrimaryButton
                  className="px-4 min-w-fit hover:opacity-85"
                  onClick={() =>
                    router.push({
                      pathname: ROUTES.signup,
                      query: { redirectTo: window.location.pathname },
                    })
                  }
                >
                  {t('sign_up')}
                </PrimaryButton>
              )}
            </>
          )}
          {showLogout && !buttonsSlot && (
            <PrimaryButton className="px-6 min-w-fit" onClick={onLogout}>
              {t('logout')}
            </PrimaryButton>
          )}
        </div>

        {isAuthenticated && !buttonsSlot && (
          <>
            <Dropdown
              label=""
              dismissOnClick={false}
              placement="bottom-end"
              className="mt-1 !-left-6 min-w-[240px]"
              renderTrigger={() => (
                <span className="flex items-center justify-center hidden w-12 h-12 text-lg font-bold text-white rounded-full cursor-pointer bg-mydra-purple md:flex">
                  {[firstName, lastName]
                    .filter(Boolean)
                    .map((name) => name?.[0] ?? '')
                    .join('')}
                </span>
              )}
            >
              <Dropdown.Header>
                <div className="flex items-center gap-3">
                  <span className="flex items-center justify-center w-10 h-10 text-sm font-bold text-white rounded-full cursor-pointer bg-mydra-purple">
                    {[firstName, lastName]
                      .filter(Boolean)
                      .map((name) => name?.[0] ?? '')
                      .join('')}
                  </span>
                  <span className="text-sm font-bold">
                    {[firstName, lastName]
                      .filter(Boolean)
                      .map((name) => name?.[0] ?? '')
                      .join(' ')}
                  </span>
                </div>
              </Dropdown.Header>
              {ACCOUNT_ROUTES.routes.map(({ title, route, icon }, index) => (
                <Link href={route} className="focus:outline-none" key={index}>
                  <Dropdown.Item
                    icon={icon}
                    className="hover:bg-mydra-purple-light focus:bg-mydra-purple-light text-gray-light"
                  >
                    {t(title)}
                  </Dropdown.Item>
                </Link>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={onLogout}
                icon={LogoutIcon}
                className="hover:bg-mydra-purple-light focus:bg-mydra-purple-light text-gray-light"
              >
                {t('logout')}
              </Dropdown.Item>
            </Dropdown>

            <span className="flex md:hidden" onClick={() => setMenuOpen(true)}>
              <MenuIcon />
            </span>
            {menuOpen && (
              <div className="fixed top-0 left-0 bg-white w-full h-full z-[999999] md:hidden">
                <div className="flex flex-col">
                  <div className="container h-[72px] flex flex-row items-center justify-between border-b border-mydra-gray">
                    <Link href={ROUTES.homepage}>
                      <Image
                        src="/images/mydra-lg.png"
                        width={1000}
                        height={250}
                        alt={t('logo_alt')}
                        priority
                        className="w-auto h-8"
                      />
                    </Link>
                    <button onClick={() => setMenuOpen(false)}>
                      <CloseIcon className="ml-auto" />
                    </button>
                  </div>
                  <div className="container flex flex-col gap-8 p-8">
                    {[MENU_ROUTES, ACCOUNT_ROUTES].map(
                      ({ title, routes }) =>
                        routes?.length > 0 && (
                          <React.Fragment key={title}>
                            <div className="flex flex-col gap-4">
                              <div className="text-sm font-semibold uppercase text-gray-light">
                                {t(title)}
                              </div>
                              {routes.map(({ title, route, icon }) => {
                                const Icon = icon
                                return (
                                  <Link
                                    href={route}
                                    className="focus:outline-none"
                                    key={route}
                                  >
                                    <div className="flex items-center gap-4 text-base text-black">
                                      <Icon width={20} />
                                      <span>{t(title)}</span>
                                    </div>
                                  </Link>
                                )
                              })}
                            </div>
                            <div className="h-[1px] border-b border-lines"></div>
                          </React.Fragment>
                        )
                    )}
                    <SecondaryButton onClick={onLogout}>
                      {t('logout')}
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </header>
  )
}
