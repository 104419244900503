import * as React from 'react'

const CheckListIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    {...props}
  >
    <g>
      <path
        d="M40.3,7.6c0-0.5-0.1-1-0.2-1.5C39.6,4.8,38.4,4,36.9,4c-4.9,0-9.8,0-14.7,0C18.5,4,14.8,4,11,4C9.4,4,8.1,5.1,7.8,6.6
		C7.7,6.9,7.7,7.2,7.7,7.5c0,11.6,0,23.3,0,34.9c0,0.8,0.3,1.3,1,1.6c0.2,0,0.5,0,0.7,0c1.6-1.1,3.2-2.1,4.7-3.2
		c0.3-0.2,0.4-0.2,0.7,0c1.2,0.9,2.4,1.8,3.7,2.6c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0,0.4,0,0.6,0c0.7-0.6,1.4-1.1,2.1-1.7
		c0.6-0.5,1.2-1,1.8-1.5c0.1,0.1,0.2,0.2,0.3,0.3c0.9,0.8,1.8,1.6,2.7,2.3c0.3,0.2,0.6,0.4,0.9,0.6c0.2,0,0.4,0,0.6,0
		c0.3-0.2,0.7-0.4,1-0.6c1.2-0.8,2.4-1.7,3.6-2.6c0.2-0.2,0.4-0.2,0.6,0c1.3,0.9,2.7,1.9,4.1,2.8c0.2,0.1,0.5,0.3,0.7,0.4
		c0.2,0,0.5,0,0.7,0c0.8-0.3,1.1-1,1.1-1.8C40.3,30.7,40.3,19.1,40.3,7.6z M37.5,40c-0.2-0.1-0.3-0.2-0.4-0.3
		c-0.9-0.6-1.8-1.3-2.7-1.9c-0.7-0.5-1.2-0.4-1.9,0c-1.4,1-2.7,2-4.1,3c-0.8-0.7-1.5-1.3-2.3-2c-0.4-0.3-0.7-0.7-1.1-1
		c-0.6-0.5-1.3-0.5-1.9,0c-1,0.9-2,1.7-3,2.6c-0.1,0.1-0.3,0.2-0.4,0.4c-0.9-0.6-1.8-1.3-2.7-1.9c-0.5-0.4-1-0.7-1.5-1.1
		c-0.6-0.4-1.1-0.4-1.7,0c-0.9,0.6-1.9,1.3-2.8,1.9c-0.1,0.1-0.2,0.1-0.4,0.2c0-0.2,0-0.3,0-0.4c0-10.7,0-21.3,0-32
		c0-0.7,0.1-0.8,0.8-0.8c8.5,0,17,0,25.5,0c0.6,0,0.8,0.1,0.8,0.7c0,10.7,0,21.3,0,32C37.5,39.6,37.5,39.8,37.5,40z"
        fill="currentColor"
      />
      <path
        d="M16.6,14.2c2.4,0,4.9,0,7.3,0c0.5,0,1,0,1.5,0c2,0,3.9,0,5.9,0c0.9,0,1.5-0.7,1.5-1.5c-0.1-0.8-0.7-1.3-1.6-1.3
		c-4.4,0-8.9,0-13.3,0c-0.5,0-0.9,0-1.4,0c-0.6,0-1.1,0.4-1.3,0.9C14.9,13.3,15.6,14.2,16.6,14.2z"
        fill="currentColor"
      />
      <path
        d="M31.6,18.9c-0.1,0-0.3,0-0.4,0c-4.8,0-9.6,0-14.4,0c-0.2,0-0.5,0-0.7,0.1c-0.6,0.2-1,0.9-0.9,1.5c0.1,0.7,0.7,1.1,1.4,1.1
		c2.5,0,5,0,7.5,0c2.4,0,4.8,0,7.2,0c0.2,0,0.4,0,0.6-0.1c0.7-0.2,1.1-0.8,1-1.5C32.8,19.5,32.2,19,31.6,18.9z"
        fill="currentColor"
      />
      <path
        d="M24.1,26.4c-1.3,0-2.5,0-3.8,0c0,0,0,0,0,0c-1.2,0-2.4,0-3.6,0c-0.9,0-1.5,0.6-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4
		c2.4,0,4.9,0,7.3,0c0.3,0,0.6-0.1,0.8-0.2c0.5-0.3,0.7-0.9,0.6-1.5C25.2,26.8,24.7,26.4,24.1,26.4z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default CheckListIcon
