import Link from 'next/link'
import { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react'

type BaseProps = {
  variant?: 'gray' | 'black'
  small?: boolean
  children: React.ReactNode
}

type ButtonProps = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & {
    href?: undefined
    disabled?: boolean
  }

type AnchorProps = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children'> & {
    href: string
    disabled?: never
  }

type SecondaryButtonProps = ButtonProps | AnchorProps

export function SecondaryButton({
  children,
  small,
  variant,
  href,
  ...props
}: SecondaryButtonProps) {
  const ComponentTag = href ? Link : 'button'

  return (
    <ComponentTag
      {...(props as any)}
      href={href}
      className={`text-base text-mydra-black outline outline-1 text-center leading-6
        ${small ? 'px-3 py-2 text-sm rounded-lg' : 'px-4 py-3 text-base rounded-xl'}
        ${variant === 'black' ? 'outline-black' : 'outline-[--lines]'}
        ${
          !href && props.disabled
            ? 'opacity-50 cursor-not-allowed outline-lines'
            : 'hover:!bg-mydra-gray'
        } ${props.className ?? ''}`}
      {...(href ? {} : { disabled: props.disabled })}
    >
      {children}
    </ComponentTag>
  )
}
