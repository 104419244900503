import * as React from 'react'

const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="12"
    viewBox="0 0 26 12"
    fill="none"
    {...props}
  >
    <path
      d="M1.55762 1.5H24.5576"
      stroke="#292929"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M1.55762 10.5H24.5576"
      stroke="#292929"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
)

export default MenuIcon
