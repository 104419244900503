import * as React from 'react'

const WalletIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M0.999963 3.05882V3.05882C0.999963 1.92177 1.92173 1 3.05879 1L14.4117 1C14.4937 1 14.5347 1 14.569 1.00479C14.7903 1.03566 14.9643 1.20962 14.9952 1.43092C15 1.46529 15 1.50627 15 1.58824V1.58824C15 2.08 15 2.32588 14.9712 2.53211C14.786 3.85992 13.7422 4.90366 12.4144 5.08888C12.2082 5.11765 11.9623 5.11765 11.4705 5.11765H10.8823M0.999963 3.05882V3.05882C0.999963 4.19588 1.92173 5.11765 3.05879 5.11765L13.8235 5.11765C14.7663 5.11765 15.2377 5.11765 15.5306 5.41054C15.8235 5.70343 15.8235 6.17484 15.8235 7.11765L15.8235 8.41176M0.999963 3.05882L0.999963 11C0.999963 12.8856 0.999963 13.8284 1.58575 14.4142C2.17154 15 3.11434 15 4.99996 15L13.8235 15C14.7663 15 15.2377 15 15.5306 14.7071C15.8235 14.4142 15.8235 13.9428 15.8235 13L15.8235 11.7059M15.8235 11.7059H12.5294C11.9249 11.7059 11.6226 11.7059 11.3957 11.5795C11.2332 11.489 11.0992 11.355 11.0087 11.1925C10.8823 10.9656 10.8823 10.6633 10.8823 10.0588V10.0588C10.8823 9.45431 10.8823 9.15205 11.0087 8.92516C11.0992 8.76267 11.2332 8.62865 11.3957 8.53814C11.6226 8.41176 11.9249 8.41176 12.5294 8.41176H15.8235M15.8235 11.7059L15.8235 8.41176"
      stroke="currentColor"
      strokeWidth="1.4"
    />
  </svg>
)

export default WalletIcon
