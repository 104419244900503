import * as React from 'react'

const PersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.3307 14V12.6667C13.3307 11.9594 13.0498 11.2811 12.5497 10.781C12.0496 10.281 11.3713 10 10.6641 10H5.33073C4.62349 10 3.94521 10.281 3.44511 10.781C2.94501 11.2811 2.66406 11.9594 2.66406 12.6667V14M10.6641 4.66667C10.6641 6.13943 9.47015 7.33333 7.9974 7.33333C6.52464 7.33333 5.33073 6.13943 5.33073 4.66667C5.33073 3.19391 6.52464 2 7.9974 2C9.47015 2 10.6641 3.19391 10.6641 4.66667Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PersonIcon
