import * as React from 'react'

const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    {...props}
  >
    <path
      d="M1.81292 17.1797L1.63912 17.3478L1.46531 17.1797L0.830104 16.5653L0.644303 16.3856L0.830104 16.2059L8.28063 9L0.830104 1.79406L0.644303 1.61436L0.830104 1.43466L1.46531 0.820299L1.63912 0.652201L1.81292 0.820299L9.27544 8.03784L16.738 0.820299L16.9118 0.652201L17.0856 0.820299L17.7208 1.43466L17.9066 1.61436L17.7208 1.79406L10.2703 9L17.7208 16.2059L17.9066 16.3856L17.7208 16.5653L17.0856 17.1797L16.9118 17.3478L16.738 17.1797L9.27544 9.96216L1.81292 17.1797Z"
      fill="#292929"
      stroke="#292929"
      strokeWidth="0.5"
    />
  </svg>
)

export default CloseIcon
